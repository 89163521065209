.container {
  display: grid;
  grid-template-areas: "nav header header"
  "nav main aside"
  "footer footer footer";
  grid-template-columns: 250px 1fr 180px;
  grid-template-rows: 80px auto 50px;
  min-height: 100vh;
  /* max-height: 100vh; */
  margin: 0;
  padding: 0;
  background-color: var(--primary-bg);
  gap: 1rem;
}


.header {
  grid-area: header;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding-right: 20px; */
  justify-content: space-between;
  align-items: center;
  /* margin-top: 20px; */
  /* margin-left: 20px; */
  border-radius: 10px;
  background-color: var(--secondary-bg);
  border-radius: 4px;
  box-shadow: 0 1px 5px #41404026;


}

.header-left {
  align-items: center;
}

.header-right {
  display: flex;
  align-items: center;
}

.header-right div {
  margin-left: 5px;
}

.notications {
  position: relative;
  margin-top: 10px;
  margin-right: 20px;

}

.notications i {
  cursor: pointer;
  margin-right: 5px;
  font-size: 18px;
}

.notications span {
  background-color: #ff4a4a;
  opacity: 0.8;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  position: absolute;
  top: -10px;
  right: -5px;
  display: flex;
  font-size: 9px;
  font-weight: 500;
  align-items: center;
  justify-content: center;

}


.nav {
  grid-area: nav;
  padding: 20px;
  /* margin-left: 20px; */
  /* margin-top: 20px; */
  background-color: var(--secondary-bg);
  border-radius: 5px;
}

.main {
  grid-area: main;
  /* overflow-y: hidden; */
  /* margin-left: 20px; */
  /* height: calc(100vh - 130px); */
  min-height: 100vh;
  padding: 1rem;
  background-color: var(--secondary-bg);
  border-radius: 5px;
  /* margin-top: 1rem; */
}

.aside {
  grid-area: aside;
  padding: 20px;
  /* margin-left: 20px; */
  /* margin-top: 20px; */
  border-radius: 5px;
  background-color: var(--secondary-bg);
  box-shadow: 0 1px 5px #79777726;
}

.footer {
  grid-area: footer;
  display: flex;
  align-items: center;
  color: var(--text-primary);
  height: 35px;
  text-align: center;
  /* margin: 10px 5px 0px 5px; */
  text-align: left;
  /* padding-left: 45px; */
  font-size: 11px;
  font-weight: 100;
  background-color: var(--secondary-bg);
  border-radius: 4px;
  box-shadow: 0 1px 5px #79777726;
  /* margin-top: 1rem; */
  justify-content: center;
}

.text-panel {
  font-size: 20px;
  font-weight: 400;
  color: var(--text-primary);
  margin-left: 15px;
}

.theme-alternate {
  cursor: pointer;
  margin-right: 50px;
}

@media screen and (max-width: 1200px) {
  .container {
    grid-template-areas:
      "nav header header"
      "nav main main"
      "footer footer footer";
  }

  .aside {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .text-panel {
    font-size: 16px;
  }

  .header {
    gap: 15px
  }

  .container {
    grid-template-areas:
      "header"
      "nav"
      "main"
      "aside"
      "footer";
    grid-template-columns: 100%;
    grid-template-rows: 60px auto auto auto 50px;
    /* Alturas ajustáveis para conteúdo responsivo */
    /* width: 90%; */
  }

  .aside {
    display: block;
  }

  /* .header,
  .nav,
  .aside,
  .footer .sidebar {
    padding: 10px;
    margin-right: 20px;
    margin-left: 20px;
  } */

  /* .main {
    padding: 10px;
    margin-right: 10px;
    margin-left: 10px;
  } */

  .profile {
    display: none;
  }

  .header {
    height: 50px;
  }

  .theme-alternate {
    cursor: pointer;
    margin-right: 20px;
  }

  .notications {
    margin-right: 0px;
  }


}

/* @media screen and (max-width: 425px) {
  .container {
    width: 100%;
  }
} */