.card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 5px;
  padding: 30px;
  width: 100%;
  background-color: var(--table-collumn-bg);
}

.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.container {
  /* padding: 2px 16px; */
  padding: 1rem 16px;
}