.table-container{
    width: 100%;
    padding: 10px;
    border-radius: 10px;
    height: calc(100vh - 190px);
  
    
}

.table-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    width: 100%;
    height: 60px;
    border-radius: 10px;
    border-start-start-radius: 10px;
    border-bottom: none;
    font-size: 16px;
    background-color: var(--table-header-bg);
    color: var(--table-text-color);
    box-shadow:0 0px 15px #20202026;


}

.table-body{
    width: 100%;
    max-height: calc(90% - 12px);
    margin-top: 5px;
    border-top: none;
    overflow: auto;
    background-color: var(--table-body-bg);
    box-shadow:0 2px 15px #20202026;

    

}

.table-body::-webkit-scrollbar{
    width: 8px;
    height: 8px;
   
}
.table-body::-webkit-scrollbar-thumb{
    border-radius: 8px;
    background-color: black;  
    visibility: hidden; 
}
.table-body:hover::-webkit-scrollbar-thumb{ 
    visibility: visible; 
}

table{
    width: 100%;
}

td i{
    font-size: 22px;  
    color: var(--quarto-plano-bg);
    font-weight: 300;
  }

table, th, td{
    padding: 16px;
    color: var(--table-text-color);
    font-size: 12px;
    font-weight: 200;
    text-align: left;
    border-collapse: collapse;
}


thead, th{
    position: static;
    top: 0;
    left: 0;
    background-color: var(--table-collumn-bg);
    color: var(--table-collumn-color);
    font-weight: 400;
}

tbody tr:nth-child(even){
    background-color: var(--child-secondary-bg);
}

tbody tr:hover{
    background-color: var(--table-hover-bg);
}

.text-primary{
    font-weight: 500;
    font-size: 12px;
  }

.table-header-data {
    display: flex;
    align-items: center;
    gap: 5px;
}
    
#btn:hover {
    cursor: pointer;
    color: var(--primary-color);
}

/* span {
    word-break: normal !important;
} */

@media screen and (max-width: 1200px) {
    .table-body {
        height: 50%;
    }
}

@media screen and (max-width: 768px) {
    .table-body {
        height: 100%;
    }
}
