.profile {
  display: flex;
  align-items: center; 
  font-size: 13px;
  margin-right: 30px;
 
}

.profile-img {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 5px; 
}

.profile-text {
  display: flex;
  flex-direction: column;
  justify-content: center; 
  font-size: 11px;
  color: var(--text-primary)
}

.profile-text-user {
  font-weight: 400;
  font-size: 12px;
}


.profile-img:hover {
  transform: scale(1.5);

}