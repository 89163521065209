.page-header {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  border-radius: 10px;
  gap: 5px;
  margin-bottom: 1rem;
}

.page-header-itens {
  margin-right: 20px;
  padding: 0px 20px;
  padding-top: 10px;
}

.page-header-itens span {
  margin-right: 20px;
  font-size: 12px;
  font-weight: 100;
}

.page-header-itens-title {
  font-size: 14px;
}

.page-header-nav ul {
  display: flex;
  justify-content: flex-start;
  background-color: var(--secondary-color);
  margin-top: 10px;
  padding: 10px;
  padding: 5px 20px;
  border-radius: 5px;
}


.page-header-nav ul li {
  display: flex;
  justify-content: center;
  /* width: 130px; */
  width: 16%;
  text-align: center;
  font-size: 13px;
  /* transition: 0.5s all; */
}

.page-header-nav ul li:hover {
  font-weight: 600;
}

.page-header-nav ul li i {
  margin-right: 5px;
  font-size: 16px;
  color: var(--text-white);
}

.page-header-nav ul li a {
  margin-top: 5px;
  display: flex;
  color: var(--text-white);
}


.active-link a,
.active-link i {
  color: var(--text-white);
  font-weight: 600;
}

.page-header-nav ul li:hover a,
.page-header-nav ul li:hover i {
  color: var(--text-white);
}

@media screen and (max-width: 425px) {
  .page-header-nav ul {
    flex-direction: column !important;
  }

  .page-header-nav ul li {
    padding: 1rem;
    margin-left: 1rem;
  }
}