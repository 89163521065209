.dashboard-cards {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
}

@media screen and (max-width: 1024px) {
    .dashboard-cards {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (max-width: 425px) {
    .dashboard-cards {
        grid-template-columns: 1fr;
    }
}