.hidden {
  display: none;
}

.sidebar-container {
  margin-top: 0px;

}


.sidebar-header {
  display: flex;
  position: relative;
  margin-bottom: 40px;
  justify-content: space-around;
  align-items: center;
}

.toggle-menu {
  display: none;
}

.sidebar-header img {
  width: 40px;
  /* border-radius: 6px;   */
  /* margin: 0px 10px 15px 5px; */
  /* filter: invert(.5); */
}


.sidebar-logo-text1 {
  font-weight: 600;
  margin-left: 5px;
  color: var(--text-logo);
}

.sidebar-logo-text2 {
  margin-top: -4px;
  margin-left: 5px;
  color: var(--text-logo);
}

.sidebar-layout li {
  height: 40px;
  margin-top: 10px;
  display: flex;
  align-items: center;
}


.sidebar-nav-link a.active {
  color: var(--sidebar-color);
  background-color: var(--primary-color);
  border-radius: 6px;
  color: var(--text-white);
}

.sidebar-nav-link a.active i {
  color: var(--text-white);
}

.sidebar-nav-link a.active:hover {
  color: var(--text-white);
}

.sidebar-nav-link a {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 13px;
  font-weight: 200;
  text-decoration: none;
  justify-content: baseline;
  min-width: 60px;
  color: var(--text-secondary);
  transition: var(--tran-02);
}

.sidebar-nav-link a:hover {
  color: var(--text-primary);
  font-weight: 400;
  border-radius: 6px;
}

.sidebar-nav-link i {
  margin-left: 20px;
  min-width: 40px;
  font-size: 20px;
}

.toggle-menu {
  pointer-events: all;
  cursor: pointer;
  transition: color .5s ease-in-out;
}

.toggle-menu:hover {
  color: var(--primary-color);
}


@media screen and (max-width: 768px) {
  .sidebar-header {
    margin-top: 20px;
  }

  .toggle-menu {
    display: block;
  }

  .menu-sidebar ul {
    display: none;
  }

  .menu-sidebar.open {
    max-height: 100%;
  }

  .menu-sidebar.open ul {
    display: block;
  }

}