/* index.css */

.toast {
  position: fixed;
  top: 30px;
  left: 50%;
  transform: translateX(-50%);
  padding: 15px;
  border-radius: 8px;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  z-index: 1000;
}

.toast-content {
  color: #fff;
}

/* Adicione estilos para diferentes status */
.toast-default {
  background-color: #333;
}

.toast-success {
  background-color: #4caf50; /* Verde para sucesso */
}

.toast-error {
  background-color: #f44336; /* Vermelho para erro */
}

.toast-warning {
  background-color: #ff9800; /* Amarelo para aviso */
}

.toast-info {
  background-color: #2196f3; /* Azul para informação */
}

.toast-visible {
  opacity: 1;
}

.toast-hidden {
  opacity: 0;
}
