 .logout-user{
    display: flex;
    flex-direction: row;
    margin-right: 20px;
    font-size: 22px;
    cursor: pointer;
    margin-left: 40px;
    border: none;         
    background: none;    
    outline: none;  

  }
  .logout-user i{
    margin-top: 2px;
  }

