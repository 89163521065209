  .revenda-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 5px 10px;
    margin-bottom: 5px;
    border-radius: 10px;

  }

  .revenda-header-itens {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    max-width: 70%;
    height: 35px;
  }

  .revenda-header-btn-inscricao {
    width: 200px;
  }

  .page-header-nav ul {
    gap: 2rem;
  }

  @media screen and (max-width: 1085px) {
    .revenda-header-btn-inscricao {
      height: 35px;
      width: 330px;
      margin: 10px 5px;
    }
  }

  .finance-cards {
    padding: 0 1rem;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr auto;
    gap: 10px;
  }

  @media screen and (max-width: 1024px) {
    .finance-cards {
      grid-template-columns: 1fr;
      /* grid-template-rows: 1fr 300px 200px; */
    }

    .finance-cards > div {
      grid-column-start: 1 !important;
      grid-column-end: 3 !important;
    }

    .page-header-nav ul {
      gap: 1rem;
      align-items: center;
      flex-direction: column;
    }

    .page-header-nav ul li {
      width: 100% !important;
    }
  }