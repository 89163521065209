  .main-content-header{
    border-radius: 10px;  
    margin-bottom: 1rem;  
  } 
  .main-content-header-title{
    padding: 5px;
    margin-left: 10px;
    border-radius: 10px;
  } 



  
  