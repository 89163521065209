.search-header{
    display: flex;
    justify-content: space-between;
    border-radius: 10px;
    max-width: 70%;
  }

.search-header-itens{
  display: flex;
  flex-direction: row;
  align-items: center;  
  justify-content: flex-start;
  width: 100%;
  max-width: 70%;
  height: 35px;
}

.search-header-itens Input{
  width: 300px;
  background-color: var(--secondary-bg);
  border: 1px solid var(--border-bg);
}

.search-header-itens Input:focus {
  outline: 1px solid var(--border-selcted-bg); 
}

.search-header-itens Button{
  width: 100%;
  /* min-width: 100px; */
  margin-left: 10px;
  padding: 10px;
}





  @media screen and (max-width: 1085px) {
   
    .search-header{
      display: block;
      width: 100%;
    }

    .search-header-itens Input {
      width: 230px;
    }

    .search-header-btn-inscricao {
      height: 35px;
      width: 330px;
      margin: 10px 5px;    
  }   
  }  
  
  @media screen and (max-width: 425px) {
    .search-header-itens Input {
      width: 170px;
    }
  }  

  @media screen and (max-width: 320px) {
    .search-header-itens Input {
      width: 150px;
    }
  }  


  