@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600&family=Poppins:ital,wght@0,300;0,400;0,500;0,700;1,100;1,600&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}


:root {

  --primary-bg: #FFFFFF;
  --secondary-bg: rgb(247, 250, 252);
  --dark-bg: #303738;

  /* --text-primary: #161616; */
  --text-secondary: #6A6C89;
  --text-color: #fffb;

  --text-white: #FFFFFF;
  --text-black: black;



  --table-header-bg: #FFFFFF;
  --table-collumn-bg: #FFFFFF;
  --table-body-bg: #EAEEF1;
  --table-hover-bg: #fffb;
  --table-text-color: #6A6C89;
  --table-collumn-color: #656783;




  --child-secondary-bg: #FFFFFF;
  --hover-secondary-bg: #EAEEF1;

  --border-bg: #6D6D7E;
  --border-selcted-bg: #6D6D7E;

  --border-ligth-bg: #cecece;
  --border-ligth-selcted-bg: #6D6D7E;

  --hover-warning: #e4b938;


  --body-color: #E4E9F7;
  --card-color: #d8ddeb;

  --primary-color: rgb(49, 130, 206);
  --secondary-color: rgba(49, 130, 206, 0.884);
  --tertiary-color: #9caffa;

  --text-primary: #333;
  --text-logo: var(--primary-color);

  --btn-primary: #2e57fa;
  --btn-dark: #303038;
  --btn-success: #28a745;
  --btn-info: #17a2b8;
  --btn-warning: #ffc107;
  --btn-danger: #dc3545;

  --tran-02: all 0.2s ease;
  --tran-03: all 0.3s ease;
  --tran-04: all 0.4s ease;
  --tran-05: all 0.5s ease;

}



[data-theme="dark"] {
  --primary-bg: #161618;
  --secondary-bg: var(--table-header-bg);
  --tertiary-bg: #25252B;


  --table-header-bg: #2F2F35;
  --table-collumn-bg: #28282E;
  --table-body-bg: #25252B;
  --table-hover-bg: #2F2F35;
  --table-text-color: #fffb;
  --table-collumn-color: #b9b9c2;



  --child-secondary-bg: #28282E;
  --hover-secondary-bg: #2F2F35;

  --text-primary: #fffb;
  --text-logo: var(--text-primary);
  --text-secondary: #b9b9c2;
  --text-color: #fffb;


  --text-white: #FFFFFF;
  --text-black: black;
  --text-tertiary: #FFFFFF;

  --secondary-color: var(--child-secondary-bg)
}

body {
  background-color: var(--primary-bg);
  color: var(--text-secondary);
  font-size: 14px;
}






a {
  text-decoration: none;
  color: var(--text-secondary);
}

i {
  text-decoration: none;
  color: var(--text-secondary);
}

ul {
  list-style-type: none;
  padding-left: 0;
}

h1,
h2,
h3,
h4,
h5 {
  color: var(--text-primary);
  font-weight: 300;
}

.icon-button {
  background: var(--primary-color);
  border: none;
  /* padding: 2px; */
  /* border-radius: 100%; */
  padding: 5px;
  border-radius: 5px;
  cursor: pointer;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 180px;
  font-size: 14px;
}

.icon-button i {
  display: flex;
  color: white;
}

.icon-button:hover {
  background: var(--secondary-color);
}

.badge i {
  font-size: 12px;
  font-style: normal;
  display: flex;
  justify-content: center;
}

.button {
  background-color: var(--primary-color);
  color: var(--text-color);
  height: 35px;
  width: 200px;
  border-radius: 5px;
  cursor: pointer;
  border: none;
  transition: all 0.5s ease;
  /* padding: 7px 10px; */
}

.button:hover {
  background: var(--secondary-color);
  color: var(--text-color);
}


.button-dark {
  background-color: var(--btn-dark);
  color: var(--text-color);
  height: 35px;
  width: 100%;
  border-radius: 5px;
  cursor: pointer;
  border: none;
  transition: all 0.5s ease;
}

.button-dark:hover {
  background-color: var(--dark-bg);
  color: var(--text-white);
}

.button-dark-close {
  background-color: var(--secondary-color);
  color: var(--text-color);
  height: 25px;
  width: 25px;
  border-radius: 50%;
  cursor: pointer;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;

  /* & svg {
    display: flex;
    justify-content: center;
    align-items: center;
  } */
}

.button-dark-close:hover {
  background-color: var(--primary-color);
  color: var(--text-color);
}

/* .button-dark-close:hover svg {
      color: var(--primary-color);
    } */


.input {
  background-color: var(--secondary-bg);
  border: 1px solid var(--ligth-bg);
  padding: 5px 5px 5px 40px;
  border-radius: 10px;
  height: 35px;
  box-sizing: border-box;
  width: 100%;
  margin-left: 5px;
  color: var(--text-primary);
  font-weight: 100;

}

.input:focus {
  outline: 1px solid var(--border-selcted-bg);
}


.textarea {
  background-color: var(--dark-bg);
  border: none;
  padding: 10px;
  box-sizing: border-box;
  width: 100%;
  margin-left: 5px;
  color: var(--text-primary);
  font-weight: 100;
  resize: none;
  min-height: 35px;
  line-height: 1.5;
  border-radius: 5px;
}

.textarea:focus {
  outline: 1px solid var(--border-selcted-bg);
}


[aria-current]:not([aria-current="false"]) {
  font-weight: bold;
}

input {
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: var(--secondary-bg);
  color: var(--text-primary);
  transition: all 0.5s;
  outline: none;
}

input:focus {
  border: 1px solid var(--primary-color);
}