.input-container {
    position: relative;
    display: flex;
    align-items: center;
    
}

/* .input {
    border: 1px solid var(--ligth-bg); 
    padding: 5px 5px 5px 40px; 
    border-radius: 10px;
    height: 100%;  
    box-sizing: border-box;
    width: 100%;
    margin-left: 5px;
    color: var(--text-primary);
    font-weight: 100;

}

.input:focus {
    outline: 1px solid var(--border-selcted-bg); 
} */

.input-icon {   
    position: absolute;
    margin-left: 15px; 
    display: flex;
    align-items: center; 
}

.input-icon svg {
    width: 20px; 
    height: auto; 
    padding-bottom: 5px;
    color: var(--text-primary);
}