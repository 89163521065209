.tab-container {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
}

.bloc-tabs {
  display: flex;
  flex-wrap: wrap;
  height: auto;
  align-items: center;
  width: 100%;
}

.tabs {
  cursor: pointer;
  border-right: 1px solid rgba(51, 48, 48, 0.274);
  color: var(--text-secondary);
  font-size: 12px;
  user-select: none;
  min-width: 120px;
  max-width: 120px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.active-tabs {
  background-color: var(--secondary-bg);
  position: relative;
  border-top: 2px solid rgb(88, 147, 241);
  align-items: start;
  padding: 4px 0;
}

.active-tabs::before {
  content: "";
  display: block;
  position: absolute;
  top: -5px;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% + 2px);
  height: 5px;
}

.content-tabs {
  flex-grow: 1;
}

.content {
  padding: 20px;
  display: none;
}

.active-content {
  display: block;
}



@media screen and (max-width: 1024px) {
  .bloc-tabs {
    max-width: 100%;
  }
}