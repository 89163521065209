.upload-cards {
    display: grid;
    grid-template-columns: repeat(2, auto);
    gap: 10px;
}

.upload-cards .flex {
    display: flex;
    gap: 10px;
    align-items: center;
}

@media screen and (max-width: 1024px) {
    .upload-cards {
        grid-template-columns: 1fr;
    }

    .upload-cards .flex {
        flex-direction: column;
    }
}

input {
    width: 100%;
}