  .container-login {
    height: 100vh;
    width: 100%;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;

  }


.longin-left{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 100vh;
  /* position: relative;
  background-image: url('../../../assets/imgs/fundo_login2.png');
  background-size: cover; 
  background-position: center; 
  background-repeat: no-repeat; 
  padding: 0px 7%; */
  background: var(--primary-color);

  & img {
    filter: brightness(0) invert(1);
  }
  
}

.longin-left h1{
  font-weight: 300;
  margin-top: 30px;
  color: var(--text-white);
}

.longin-left h3{
  color: var(--text-white);
}
.longin-left p{
  font-weight: 100;
  color: var(--text-secondary);
}


.longin-rigth{  
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 100vh;
  background-color: #FFFFFF;
  
}

.login-body{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: -50px;
  width: 100%;
}

.login-header h1{  
  color: var(--primary-bg);  
  margin-bottom: 30px;
}
.login-header span{
  font-size: 24px;
}

.login-form{
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--text-secondary);
  width: 400px;
}

.login-form form{
  width: 450px;
}

.login-form button{
  margin-top: 10px;
  color: var(--primary-bg);
}

.login-form input{
  background-color: var(--primary-bg);
  border: 1px solid #cecece;   
  color: var(--text-secondary);
  padding: 5px 5px 5px 40px; 
  border-radius: 10px;
  height: 35px;  
  box-sizing: border-box;
  width: 100%;
  margin-left: 5px;  
  font-weight: 100;
  margin-bottom: 10px;
}

.login-form:focus {
  border: 1px solid var(--border-ligth-selcted-bg); 
} 


.input-user{
  margin-bottom: 10px;
}

.form-observacao{
  text-align: center;
}


.input-user label{
  font-size: 12px;
}

.input-user svg{
  margin-top: 10px;
  color: var(--primary-bg);
  font-weight: 700;
}


@media screen and (max-width: 768px) {
  .container-login {
    display: flex;
    flex-direction: column;    
  }
  .longin-left{
    
    width: 100%;
   
  }
    
  .longin-rigth{  
    width: 100%;
    
  }
}