.container-modal {
  display: flex;
  justify-content: center;
  align-items: center;

}

.modal-overlay {
  background-color: transparent;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;

  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  width: 600px;
  /* height: 300px; */
  background-color: var(--primary-bg); /* Cor de fundo primária do seu esquema */
  color: var(--text-primary); 
  padding: 10px;
  border-radius: 4px;
  box-shadow:0 2px 15px #79777726;
}

.modal-header{
  display: flex;
  justify-content: space-between;
  padding: 5px 5px;
  font-size: 12px;  
  border-bottom: 1px solid #ccc;
  
  & h3 {
    color: var(--table-text-color);
  }
}
/* .modal-header svg{
  margin-top: 5px;
  align-self: center;
} */


.modal-body{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;

  
}

.modal-main{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  width: 100%; 
  max-height: 200px;
  color: var(--table-text-color);
}


.modal-button-insc{
  flex-grow: 3;
  width: 50%;
}  

.modal-textarea{
 margin-top: 20px;
 width: 100%;
}
.modal-textarea textarea{
 height: 120px;
 color: var(--text-color);
}

.modal-textarea input{
 height: 30px; 
}

.modal-textarea-itens{
  display: flex;
  flex-direction: row; }
.modal-textarea-itens svg{
  font-size: 26px;
  margin-left: 10px;
  cursor: pointer;

}
